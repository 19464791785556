import React, { Component } from 'react'
import { Button, Icon, Flag } from 'semantic-ui-react'


export default class Header extends Component {

    state = { currentLanguage: "fr" }

    handleClickOnSecondary = () => {
        console.log("fchhhhhh", localStorage.getItem('maString'));

    }

    toogleLanguage = () => {
        if (this.state.currentLanguage.includes("fr"))
        {
            this.setState({ currentLanguage: "en" })
            this.props.changeLanguage("en");
        }
        else {
            this.setState({ currentLanguage: "fr" })
            this.props.changeLanguage("fr");
        }


    }

    setEnglish = () => {
        this.props.changeLanguage("en");
    }

    setFrench = () => {
        this.props.changeLanguage("fr");
    }


    render() {
        return (
            <div class="header">

                <div class="container-header-buttons">
                    {this.state.currentLanguage.includes("fr") && <div class="mon-button">
                        <Button  onClick={this.toogleLanguage}>
                        <Button.Content visible><Icon loading name='world' />Français</Button.Content>
                        </Button>

                    </div>}

                    {this.state.currentLanguage.includes("en") && <div class="mon-button">
                        <Button  onClick={this.toogleLanguage}>
                            <Button.Content visible><Icon loading name='world' />English</Button.Content>

                        </Button>

                    </div>}


                </div>
            </div>)
    }
}
