import React, { Component } from 'react'
import { Message } from 'semantic-ui-react'
import { Container, Header, Label, Icon } from 'semantic-ui-react'


export default class RowElement extends Component {
    render() {

        //console.log("texte to change = " + this.props.stringToSearch +" en " , this.props.stringToSearch.replace(this.props.stringToSearch,<b>{this.props.stringToSearch}</b>) );
        return (
            <div className="listElements">
                <Container textAlign='left'>
                    <Message visible>


                        <Label  size="medium">
                            <Icon name='book' /> {this.props.entry.book.toUpperCase()}
                        </Label>


                        <Message
                            header={this.props.entry.headline}
                            content={this.props.entry.text}
                        />

                    </Message>
                </Container>
                <br />
            </div>
        )
    }
}
