import React, { Component } from 'react'
import Header from './components/Header'
import InputSearch from './components/InputSearch';
import List from './components/List';
import './App.css';
import { Pagination } from 'semantic-ui-react';

import myData from './database/boukhari.json';

const axios = require('axios');

export default class App extends Component {

  state = {
    stringToSearch: "",
    resultsToShow: [],
    currentPage: 0,
    totalReults: 0,
    languageBool: 0,
    rechercheEnCours: false,
    currentLanguage: "fr",
    book: ""
  }

  componentDidMount() {
  }



  reset = (string) => {

    let newString = this.state.stringToSearch.toLowerCase().replace(string.trim().toLowerCase(), "").trim();
    if (newString) this.setState({ stringToSearch: newString }, () => {
      this.firstStringToSearch(this.state.stringToSearch)
    });
    else this.setState({ resultsToShow: [], currentPage: 0, totalReults: 0, stringToSearch: "", book: "" })

  }


  setBook = (book) => {

    console.log("change book to " + book)
    if ((book.includes("All") || book.includes("Tous")) )
      this.setState({ book: "" }, () => {
        this.firstStringToSearch(this.state.stringToSearch)
      }
      );
    else
      this.setState({ book: book }, () => {
        this.firstStringToSearch(this.state.stringToSearch)
      }
      );
  }



  changeLanguage = (language) => {

    console.log("change language to " + language)



    if (!this.state.currentLanguage.includes(language))
      this.setState(() => ({ currentLanguage: language, resultsToShow: [], currentPage: 0, totalReults: 0, stringToSearch: "", book: "" }))
  }

  firstStringToSearch = (stringToSearch) => {


    //if (this.state.currentPage == 0) this.getTotalResults(stringToSearch);

    this.setState(() => { return ({ rechercheEnCours: true, resultsToShow: [], currentPage: 0, totalReults: 0 }) });

    const url = 'http://database.mouminoune.com/get/' + stringToSearch + '/' + this.state.currentLanguage + '/' + 1 + (this.state.book ? '/\"' + this.state.book + '\"' : '');
    console.log("on va chercher cette URL : ", url);
    axios.get(url)
      .then(response => {
        //this.setState({ stringToSearch: stringToSearch });
        console.log("response.data=", response.data);
        this.setState({ stringToSearch: stringToSearch, resultsToShow: response.data.data, totalReults: response.data.count, rechercheEnCours: false });
      })
      .catch(error => {
        console.log("actched error = " + error);
      });

  }


  /*
    getTotalResults = (stringToSearch) => {
  
  
      this.setState(() => ({ resultsToShow: [], totalReults: 0 }));
  
      axios.get('http://database.mouminoune.com/count/' + stringToSearch + '/en/')
        .then(response => {
          console.log("COUNT =", response.data);
          this.setState(() => ({ totalReults: response.data, totalReults: response.data.count }))
        })
        .catch(error => {
          console.log("actched error = " + error);
        });
  
    }
  */



  onPageChange = (event, data) => {
    console.log("change event = ", event + " data = ", data.activePage);
    this.setState(() => ({ currentPage: (data.activePage) }));

    this.gotoPageStringToSearch(this.state.stringToSearch, data.activePage)
  }



  gotoPageStringToSearch = (stringToSearch, page) => {


    //if (this.state.currentPage == 0) this.getTotalResults(stringToSearch);

    this.setState({ resultsToShow: [], rechercheEnCours: true });

    const url = 'http://database.mouminoune.com/get/' + stringToSearch + '/' + this.state.currentLanguage + '/' + page + (this.state.book ? '/\"' + this.state.book + '\"' : '');
    console.log("on va chercher cette URL : ", url);
    axios.get(url)
      .then(response => {
        //this.setState({ stringToSearch: stringToSearch });
        console.log("response.data=", response.data.data);
        this.setState({ resultsToShow: response.data.data, rechercheEnCours: false });
      })
      .catch(error => {
        console.log("actched error = " + error);
      });

  }






  render() {
    return (
      <div className="App">
        <Header changeLanguage={this.changeLanguage} />
        <div className="logo">
          <img src={ this.state.currentLanguage==="fr" ? require("./images/mouminoune-fr.png") : require("./images/mouminoune-en.png")} alt="" />
          <InputSearch stringToSearch={this.firstStringToSearch} />
          <br />


          <List reset={this.reset} book={this.state.book} setBook={this.setBook} currentLanguage={this.state.currentLanguage} resultsToShow={this.state.resultsToShow} rechercheEnCours={this.state.rechercheEnCours} stringToSearch={this.state.stringToSearch} totalReults={this.state.totalReults} />
          <br />

          {this.state.totalReults > 20 && <Pagination
            boundaryRange={0}
            defaultActivePage={this.state.currentPage}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            totalPages={Math.ceil((this.state.totalReults) / 20)}
            onPageChange={this.onPageChange}
          />
          }
          <br />
          <br />




        </div>
      </div>
    )
  }
}
