import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'
import { type } from 'os';

export default class InputSearch extends Component {

    state = { searchString: "" }
    handleChange = (e, data) => {
        this.setState({ searchString: data.value });
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.searchString.trim()) {
            // console.log('do validate the search string is ' + this.state.searchString);
            this.props.stringToSearch(this.state.searchString);


            /*
            const arrayOfHadith = JSON.parse(localStorage.getItem('arrayOfHadith'));

            console.log(typeof arrayOfHadith )

            const newArray = arrayOfHadith.filter(e => e.hadith.toLowerCase().includes(this.state.searchString.toLowerCase()))

            for(const hadith of newArray) console.log("hadith =", hadith,"\n");
*/

            //alert(newArray.length)
            this.setState({ searchString: "" })



        }
    }

    handleIconValidation = (e) => {
        console.log('do validate the search string is ' + this.state.searchString);
        if (this.state.searchString.trim())
            this.props.stringToSearch(this.state.searchString);
        this.setState({ searchString: "" })

    }





    render() {
        return (



            <Input onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                value={this.state.searchString}
                className="input-field"
                action={{
                    icon: 'search',
                    onClick: () => this.handleIconValidation()
                }}
                placeholder='Search...' />
        )
    }

}
