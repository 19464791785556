import React, { Component } from 'react'
import { Dimmer, Loader, Label, Icon, Message, Dropdown } from 'semantic-ui-react'
import RowElement from './RowElement';


export default class List extends Component {

    state = { filterText: "" }

    books = {
        fr: ["Tous", "Coran", "Sahih Boukhari", "Sahih Muslim", "Riyad As Salihin"],
        en: ["All", "40 hadith Qudsi", "Sahih Boukhari", "Sahih Muslim", "An Nawawwis Forty Hadith", "Sunan Abu-Dawud", "Malik's Muwatta"]
    };

    filterbyBookText = {
        fr: "Livre",
        en: "Book"
    };


    filter = {
        fr: "Filtrer",
        en: "Filter"
    }

    resetfilterText = () => {

        this.setState({ filterText: "" });
    }


    removeKeyWordLabel = (string) => {
        this.props.reset(string);
    }


    onClick = (book) => {

        this.setState({ filterText: book })
        this.props.setBook(book);
        console.log(book)
    }




    render() {

        const searchWordArray = this.props.stringToSearch.split(' ');

        let maxSize = 0;
        for (const item of searchWordArray)
            if (item.length >= maxSize) maxSize = item.length;



        //console.log("ICI on va afficher la liste qui est de la taille : " + this.props.resultsToShow.length + " typeof = " + this.props.resultsToShow);
        if (maxSize < 3 && this.props.stringToSearch) {
            if (this.props.currentLanguage.includes("fr"))
                return (
                    <div className="warning" >
                        <Message
                            icon='warning sign'
                            header='Mots clefs trop courts.'
                            content='Utilisez des mots clefs plus long.'
                        />
                    </div>
                );
            else
                return (
                    <div className="warning" >
                        <Message
                            icon='warning sign'
                            header='keywords too short.'
                            content='Please use words long enough.'
                        />
                    </div>
                );


        }
        else

            return (
                <div className="listElements">

                    {this.props.stringToSearch && !this.props.rechercheEnCours && <p className="statistics">


                        {false && <Label size="large">
                            <Icon name='folder open' /> {this.props.totalReults}
                        </Label>}



                        <div className="keyWords">
                        <Dropdown
                            text={this.props.book ? this.state.filterText + " (" + this.props.totalReults + ")" : " " + this.filter[this.props.currentLanguage]  +" (" + this.props.totalReults + ")"}
                            icon='filter'
                            floating
                            labeled
                            button
                            className='icon'

                        >

                            
                            <Dropdown.Menu>
                                <Dropdown.Header icon='tags' content={this.filterbyBookText[this.props.currentLanguage]} />
                                <Dropdown.Divider />
                                {this.books[this.props.currentLanguage].map((b) => { return <Dropdown.Item onClick={() => this.onClick(b)}>{b} </Dropdown.Item> })}
                            </Dropdown.Menu>
                        </Dropdown>




                        

                        {this.props.stringToSearch.split(" ").map((e) => {
                            if (e.trim()) return (

                                <Label   color='blue' as='a' size="large">
                                    {e}
                                    <Icon name='delete' onClick={() => this.removeKeyWordLabel(e)} />
                                </Label>)


                        })}

</div>




                    </p>



                    }

                    {this.props.rechercheEnCours && <Dimmer active inverted><Loader /></Dimmer>}
                    {this.props.resultsToShow.length > 0 && this.props.resultsToShow.map((entry) => (<RowElement stringToSearch={this.props.stringToSearch} entry={entry}><br /><br /></RowElement>))}




                </div >
            )
    }


}
